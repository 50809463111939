import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/button";

import ActiveIcon from "../../assets/images/icons/iconAction.svg";
import star from "../../assets/images/icons/star.svg";
import terms from "../../assets/images/Dealer/Address.svg";
import dealer from "../../assets/images/Dealer/Name.svg";
import Primary from "../../assets/images/SetPrimary.png";
import deleteUser10 from "../../assets/images/deleteUser.svg";
import assign from "../../assets/images/Unassign.png";
import Search from "../../assets/images/icons/SearchIcon.svg";
import clearFilter from "../../assets/images/icons/Clear-Filter-Icon-White.svg";
import NotificationImage from "../../assets/images/icons/Notification-icon.svg";
import shorting from "../../assets/images/icons/shorting.svg";
import Grid from "../../common/grid";
import Input from "../../common/input";
import DataTable from "react-data-table-component";
import { RotateLoader } from "react-spinners";
import Modal from "../../common/model";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  changePrimaryByUserIdCustomerPortal,
  deleteUserByUserId,
  getUserListByDealerId,
  userDetailsById,
  addUserToCustomerPortal,
  updateUserDetailsById,
} from "../../services/userServices";
import Select from "../../common/select";
import {
  addCustomerAddressById,
  deleteCustomerAddress,
  editCustomerAddressById,
  getCustomerDetailsByIdCustomerPortal,
  getCustomerUsersByIdCustomerPortal,
} from "../../services/customerServices";
import { useMyContext } from "../../context/context";
import AddItem from "../../assets/images/icons/addItem.svg";
import Headbar from "../../common/headBar";
import RadioButton from "../../common/radio";
import Tabs from "../../common/tabs";
import deleteUser123 from "../../assets/images/Disapproved.png";
import Cross from "../../assets/images/Cross.png";
import make from "../../assets/images/star.png";
import edit from "../../assets/images/edit-text.png";
import delete1 from "../../assets/images/delete.png";
import PasswordInput from "../../common/passwordInput";
import {
  addSuperAdminMembers,
  changePasswordbyToken,
  getUserNotificationData,
  updateNotificationData,
} from "../../services/extraServices";
import SingleView from "../../common/singleView";
import InActiveButton from "../../common/inActiveButton";
import Card from "../../common/card";
import textFile from "../../common/textFile";
import { cityData } from "../../stateCityJson";
import CollapsibleDiv from "../../common/collapsibleDiv";
import SwitchButton from "../../common/switch";
import { Notifications } from "../../notificationjson";
function CustomerUser() {
  const { toggleFlag } = useMyContext();
  const [selectedAction, setSelectedAction] = useState(null);
  const [userList, setUserList] = useState([]);
  const [loginEmail, setLoginEmail] = useState([]);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false);
  const [isModalOpen, SetIsModalOpen] = useState(false);
  const [isprimary, SetIsprimary] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editAddressLoader, setEditAddressLoader] = useState(false);
  const [active, setActive] = useState(true);
  const [mainStatus, setMainStatus] = useState(true);
  const [details, setDetails] = useState(true);
  const [servicerStatus, setServiceStatus] = useState(true);
  const [firstMessage, setFirstMessage] = useState("");
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [notificationList, setNotificationList] = useState([])
  const [notification, setNotification] = useState({})
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndex1, setActiveIndex1] = useState(null);
  const [showdata, setShowdata] = useState(true);
  const [addressData, setAddressData] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [secondMessage, setSecondMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [createAccountOption, setCreateAccountOption] = useState("yes");
  const [userStatus, setUserStatus] = useState(true);
  const [primaryText, SetPrimaryText] = useState("");
  const [secondaryText, SetSecondaryText] = useState("");
  const [timer, setTimer] = useState(3);
  const dropdownRef = useRef(null);
  const state = cityData;
  const [loading1, setLoading1] = useState(false);
  const [activeButton, setActiveButton] = useState("myAccount");
  const [isModalOpen12, setIsModalOpen12] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    lastName: "",
    firstName: "",
    phoneNumber: "",
    position: "",
    status: createAccountOption == "yes" ? true : false,
    id: "",
  });
  const [initialFormValues1, setInitialFormValues1] = useState({
    address: "",
    addressId: "",
    city: "",
    state: "",
    zip: "",
  });

  const [initialFormValues2, setInitialFormValues2] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [currentPage, setCurrentPage] = useState(1); // Tracks current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Tracks rows per page

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };
  const closeUserModal = () => {
    setIsUserModalOpen(false);
    setInitialFormValues({
      lastName: "",
      firstName: "",
      phoneNumber: "",
      position: "",
      status: createAccountOption == "yes" ? true : false,
      id: "",
    });
  };
  const openAddressModal = (data) => {
    console.log(data);
    setInitialFormValues1({
      addressId: data._id,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
    })
    setIsAddressModalOpen(true);
  };
  const closeAddressModal = () => {
    setIsAddressModalOpen(false);

  };

  const openAddAddressModal = () => {
    setInitialFormValues1({
      addressId: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    })
    setAddAddressModalOpen(true);
  };
  const closeAddAddress = () => {
    setAddAddressModalOpen(false);
    setInitialFormValues1({
      addressId: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    })

  };
  console.log("toggleFlag", createAccountOption);
  const [loading, setLoading] = useState(false);
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue === "yes" ? true : false);
    userValues.setFieldValue("status", selectedValue === "yes" ? true : false);
    setCreateAccountOption(selectedValue);
  };
  const getUserList = async () => {
    const result = await getCustomerUsersByIdCustomerPortal();
    console.log(result.result);
    setUserList(result.result);
  };
  const getCustomerDetails = async () => {
    setLoading1(true);
    const result = await getCustomerDetailsByIdCustomerPortal();
    console.log(result.result.dealer.userAccount);
    setUserStatus(result.result.dealer.userAccount);
    setCreateAccountOption(
      result.result.dealer.userAccount == true ? "yes" : "no"
    );
    setDetails(result.result);
    setCustomerId(result.loginMember._id)
    SetIsprimary(result.loginMember.isPrimary);
    setAddressData(result.result.addresses)
    setLoginEmail(result.result.email)
    setServiceStatus(result.loginMember.status);
    setLoading1(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Close the dropdown if the click is outside of it
      setSelectedAction(null);
    }
  };
  useEffect(() => {
    getUserList();
    getCustomerDetails();
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    let intervalId;

    if ((isModalOpen || (isModalOpen12 && timer > 0)) && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      closeModal1();
      setSelectedAction(null);
      closeModal();
      closeModal12();
      getUserList();
    }

    if (!isModalOpen && !isModalOpen12) {
      clearInterval(intervalId);
      setTimer(3);
    }

    setLoading(false);

    return () => {
      clearInterval(intervalId);
    };
  }, [isModalOpen, isModalOpen12, timer]);

  const closeModal = () => {
    SetIsModalOpen(false);
  };

  const openModal = () => {
    SetIsModalOpen(true);
    getUserList();
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const handleSelectChange = async (name, value) => {
    formik1.setFieldValue(name, value);
  };

  const handleSelectChange12 = async (name, value) => {
    address.setFieldValue(name, value);
  };
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  const openModal1 = (id) => {
    setDeleteId(id);
    setIsModalOpen1(true);
  };
  const status = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const closeModal2 = () => {
    setIsModalOpen2(false);
    userValues.resetForm();
  };
  const openModal2 = () => {
    setIsModalOpen2(true);
  };
  const openUserModal = () => {
    setInitialFormValues({
      lastName: "",
      firstName: "",
      phoneNumber: "",
      position: "",
      status: createAccountOption == "yes" ? true : false,
      id: "",
    });
    setIsUserModalOpen(true);
  };
  const closeModal12 = () => {
    setIsModalOpen12(false);
    formik.resetForm();
  };
  const openModal12 = () => {
    setIsModalOpen12(true);
  };
  const handleStatusChange = async (row, newStatus) => {
    console.log(row);
    try {
      setUserList((userData) => {
        return userData.map((user) => {
          if (user._id === row._id) {
            return {
              ...user,
              status: newStatus === "active" ? true : false,
            };
          }
          return user;
        });
      });

      const result = await updateUserDetailsById({
        id: row._id,
        status: newStatus === "active" ? true : false,
      });

      console.log(result);
    } catch (error) {
      console.error("Error in handleStatusChange:", error);
    }
  };
  const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .max(30, "Must be exactly 30 characters"),
      email: Yup.string()
        .required("Required")
        .matches(emailValidationRegex, "Invalid email address")
        .transform((originalValue) => originalValue.trim()),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("Form values:", values);
      setAddLoading(true);
      const result = await addUserToCustomerPortal(values);
      console.log(result);
      if (result.code == 200) {
        setAddLoading(false);
        SetPrimaryText("User Added Successfully ");
        SetSecondaryText("user Added successfully ");
        openModal();
        toggleFlag();
        setTimer(3);
        getUserList();
      } else {
        if (result.code === 401) {
          formik.setFieldError("email", "Email already in use");
        }
        SetIsModalOpen(true);
        setAddLoading(false);
      }
      closeModal2();
    },
  });

  const calculateDropdownPosition = (index) => {
    const isCloseToBottom = userList.length - index <= 10000;
    return isCloseToBottom ? "bottom-[1rem]" : "bottom-[1rem]";
  };


  const deleteUser = async () => {
    const result = await deleteUserByUserId(deleteId);
    console.log(result);
    if (result.code === 200) {
      getUserList();
      setIsModalOpen12(true);
      // closeModal1();
    }
  };
  const editUser = async (id) => {
    console.log(id);
    const result = await userDetailsById(id);
    console.log(result.result);
    setActive(result.result.isPrimary);
    setMainStatus(result.mainStatus);
    setInitialFormValues({
      id: id,
      lastName: result?.result?.lastName,
      firstName: result?.result?.firstName,
      phoneNumber: result?.result?.phoneNumber,
      position: result?.result?.position,
      status: result?.result?.status,
    });
    openModal2();
  };

  const makeUserPrimary = async (row) => {
    console.log(row._id);
    const result = await changePrimaryByUserIdCustomerPortal(row._id);
    console.log(result);
    if (result.code === 200) {
      SetPrimaryText("It's set to Primary");
      SetSecondaryText("We have successfully made this user primary");
      toggleFlag();
      openModal();
      getCustomerDetails();
    }
  };

  const filterUserDetails = async (data) => {
    try {
      setLoading(true);
      const res = await getCustomerUsersByIdCustomerPortal("", data);
      setUserList(res.result);
      let local = JSON.parse(localStorage.getItem("userDetails"));
      // localStorage.removeItem('userDetails')
      local.userInfo = {
        lastName: res?.result?.[0]?.lastName,
        firstName: res?.result?.[0]?.firstName,
      };
      localStorage.setItem("userDetails", JSON.stringify(local));
    } catch (error) {
      console.error("Error fetching category list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterIconClick = () => {
    formikUSerFilter.resetForm();
    console.log(formikUSerFilter.values);
    getUserList();
  };
  const formikUSerFilter = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      email: Yup.string(),
      phone: Yup.number(),
    }),
    onSubmit: async (values) => {
      filterUserDetails(values);
    },
  });

  const handleSelectChange1 = async (name, selectedValue) => {
    Addaddress.setFieldValue(name, selectedValue);
  };

  const formik1 = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      setEditLoading(true);
      const result = await updateUserDetailsById(values);
      console.log(result);
      if (result.code == 200) {
        setEditLoading(false);
        SetPrimaryText("User Updated Successfully ");
        SetSecondaryText("user updated successfully ");
        // setFirstMessage("User Edited Successfully ");
        // setSecondMessage("user edited successfully ");
        openModal();
        setTimer(3);
        filterUserDetails();
      } else {
        setEditLoading(false);
      }
      closeModal2();
    },
  });

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups of 3 digits

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return original phone number if it couldn't be formatted
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="flex relative">
          {row.isPrimary && (
            <img src={star} alt="" className="absolute -left-3 top-0" />
          )}
          <span className="self-center pt-2 ml-3">
            {row.firstName} {row.lastName}
          </span>
        </div>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => "+1 " + formatPhoneNumber(row.phoneNumber),
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
      reorder: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <div className="relative">
          <div
            className={` ${row.status === true ? "bg-[#6BD133]" : "bg-[#FF4747]"
              } absolute h-3 w-3 rounded-full top-[33%] ml-[8px]`}
          ></div>
          <select
            disabled={row.isPrimary || !servicerStatus}
            value={row.status === true ? "active" : "inactive"}
            onChange={(e) => handleStatusChange(row, e.target.value)}
            className="text-[12px] border border-gray-300 text-[#727378] pl-[20px] py-2 pr-1 font-semibold rounded-xl"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
    },
    {
      name: "Action",
      minWidth: "auto", // Set a custom minimum width
      maxWidth: "90px", // Set a custom maximum width
      cell: (row, index) => {
        // console.log(index, index % 10 == 9)
        return (
          <div className="relative">
            <div
              onClick={() =>
                setSelectedAction(
                  selectedAction === row.email ? null : row.email
                )
              }
            >
              <img
                src={ActiveIcon}
                className="cursor-pointer	w-[35px]"
                alt="Active Icon"
              />
            </div>
            {selectedAction === row.email && (
              <SingleView
                ref={dropdownRef}
                className={`absolute z-[9999] ${!row.isPrimary ? "w-[140px]" : "w-[140px]"
                  } drop-shadow-5xl -right-3 mt-2 py-1 border rounded-lg shadow-md ${calculateDropdownPosition(
                    index
                  )}`}
              >
                {!row.isPrimary && row.status && (
                  <div
                    className="text-left cursor-pointer flex py-1 px-2 border-b"
                    onClick={() => makeUserPrimary(row)}
                  ><div
                      style={{
                        maskImage: `url(${make})`,
                        WebkitMaskImage: `url(${make})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center singleViews mr-2 h-4 w-4 "
                    />
                    {/* <img src={make} className="w-4 h-4 mr-2" />{" "} */}
                    <span className="self-center"> Make Primary </span>
                  </div>
                )}

                <div
                  className="text-left cursor-pointer flex py-1 px-2 border-b"
                  onClick={() => editUser(row._id)}
                >
                  <div
                    style={{
                      maskImage: `url(${edit})`,
                      WebkitMaskImage: `url(${edit})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  {/* <img src={edit} className="w-4 h-4 mr-2" />{" "} */}
                  <span className="self-center">Edit </span>
                </div>
                <div
                  onClick={() => openNotification(row._id)}
                  className={`text-left cursor-pointer flex ${!row.isPrimary && 'border-b'} py-1 px-2`}
                >
                  <div
                    style={{
                      maskImage: `url(${NotificationImage})`,
                      WebkitMaskImage: `url(${NotificationImage})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  {/* <img src={edit} className="w-4 h-4 mr-2" />{" "} */}
                  <span className="self-center">Notification </span>
                </div>
                {!row.isPrimary && (
                  <div
                    className="text-left cursor-pointer flex py-1 px-2"
                    onClick={() => openModal1(row._id)}
                  >
                    <div
                      style={{
                        maskImage: `url(${delete1})`,
                        WebkitMaskImage: `url(${delete1})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center singleViews mr-2 h-4 w-4 "
                    />
                    {/* <img src={} className="w-4 h-4 mr-2" />{" "} */}
                    <span className="self-center">Delete</span>
                  </div>
                )}
              </SingleView>
            )}
          </div>
        );
      },
    },
  ];

  const columns12 = [
    {
      name: "Name",
      selector: "name",
      // sortable: true,
      cell: (row) => (
        <div className="flex relative">
          {row.isPrimary && (
            <img src={star} alt="" className="absolute -left-3 top-0" />
          )}
          <span className="self-center pt-2 ml-3">
            {row.firstName} {row.lastName}
          </span>
        </div>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      // sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => "+1 " + formatPhoneNumber(row.phoneNumber),
      // sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      // sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      cell: (row) => (
        <div className="relative">
          <div
            className={` ${row.status === true ? "bg-[#6BD133]" : "bg-[#FF4747]"
              } absolute h-3 w-3 rounded-full top-[33%] ml-[8px]`}
          ></div>
          <select
            disabled={true}
            value={row.status === true ? "active" : "inactive"}
            onChange={(e) => handleStatusChange(row, e.target.value)}
            className="text-[12px] border border-gray-300 text-[#727378] pl-[20px] py-2 pr-1 font-semibold rounded-xl"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
    },
    {
      name: "Action",
      minWidth: "auto", // Set a custom minimum width
      maxWidth: "90px", // Set a custom maximum width
      cell: (row, index) => {
        // console.log(index, index % 10 == 9)
        return (
          <>
            {row.email === loginEmail &&
              <div className="relative">
                <div
                  onClick={() =>
                    setSelectedAction(
                      selectedAction === row.email ? null : row.email
                    )
                  }
                >
                  <img
                    src={ActiveIcon}
                    className="cursor-pointer	w-[35px]"
                    alt="Active Icon"
                  />
                </div>
                {selectedAction === row.email && (
                  <SingleView
                    ref={dropdownRef}
                    className={`absolute z-[9999] ${!row.isPrimary ? "w-[140px]" : "w-[140px]"
                      } drop-shadow-5xl -right-3 mt-2 py-1 border rounded-lg shadow-md ${calculateDropdownPosition(
                        index
                      )}`}
                  >
                    <div
                      onClick={() => openNotification(row._id)}
                      className={`text-left cursor-pointer flex ${!row.isPrimary && 'border-b'} py-1 px-2`}
                    >
                      <div
                        style={{
                          maskImage: `url(${NotificationImage})`,
                          WebkitMaskImage: `url(${NotificationImage})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="self-center singleViews mr-2 h-4 w-4 "
                      />
                      {/* <img src={edit} className="w-4 h-4 mr-2" />{" "} */}
                      <span className="self-center">Notification </span>
                    </div>
                  </SingleView>
                )}
              </div>}

          </>

        );
      },
    },
  ];

  const initialValues2 = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);
    passwordChange(values);
    setSubmitting(false);
  };

  const passwordChange = async (value) => {
    setLoading1(true);
    delete value.confirmPassword;

    try {
      const res = await changePasswordbyToken(value);
      console.log(res);
      if (res.code == 200) {
        SetPrimaryText("Updated  Successfully ");
        SetSecondaryText("User Password updated  successfully ");
        SetIsModalOpen(true);
        setTimer(3);
      } else {
        setFirstMessage("Error");
        setSecondMessage(res.message);
        setIsPasswordOpen(true);
      }
    } catch (error) {
      console.error("Error changing password:", error);
    } finally {
      setLoading1(false);
    }
    console.log(value);
  };

  const passwordChnageForm = useFormik({
    initialValues: initialValues2,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: handleSubmit,
  });

  const closePassword = () => {
    setIsPasswordOpen(false);
  };

  const CustomNoDataComponent = () => (
    <Card className="text-center my-5">
      <p>No records found.</p>
    </Card>
  );

  const userValues = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      email: Yup.string()
        .required("Required")
        .matches(emailValidationRegex, "Invalid email address")
        .transform((originalValue) => originalValue.trim()),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("Form values:", values);
      setLoading(true);
      const result = await addSuperAdminMembers(values);
      console.log(result);
      if (result.code == 200) {
        setLoading(false);
        setTimer(3);
        SetPrimaryText("User Add Successfully ");
        SetSecondaryText("user Add successfully ");
        SetIsModalOpen(true);
        setIsUserModalOpen(false);
        getUserList();
      } else {
        setLoading(false);
        SetIsModalOpen(true);
        if (result.code === 401) {
          userValues.setFieldError("email", "Email already in use");
        }
      }
      closeModal2();
    },
  });

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const Address = [
    {
      name: "Serial #",
      cell: (row, index) => (
        <div className="flex relative">
          {row.isPrimary && (
            <img src={star} alt="" className="absolute -left-3 top-0" />
          )}
          <span className="self-center pt-2 ml-3">
            {(currentPage - 1) * rowsPerPage + index + 1}
          </span>
        </div>
      ),
      sortable: true,
      style: { whiteSpace: "pre-wrap" },
    },
    {
      name: "Street Address",
      selector: (row) => row.address,
      sortable: true,
      style: { whiteSpace: "pre-wrap" },
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      style: { whiteSpace: "pre-wrap" },
    },
    {
      name: "State",
      selector: (row) => row?.state,
      sortable: true,
      style: { whiteSpace: "pre-wrap" },
    },
    {
      name: "Zip",
      selector: (row) => row?.zip,
      sortable: true,
      style: { whiteSpace: "pre-wrap" },
    },
    {
      name: "Action",
      minWidth: "auto",
      maxWidth: "80px",
      cell: (row, index) => {
        // console.log(index, index % 10 == 9)
        return (
          <div className="relative">
            {!row.isPrimary && <div
              onClick={() =>
                setSelectedAction(
                  selectedAction === index ? null : index
                )
              }
            >
              <img
                src={ActiveIcon}
                className="cursor-pointer w-[35px]"
                alt="Active Icon"
              />
            </div>
            }
            {selectedAction === index && (
              <SingleView
                ref={dropdownRef}
                onClick={() => setSelectedAction(null)}
                className={`absolute z-[2] w-[100px] drop-shadow-5xl -right-3 mt-2 py-1 border rounded-lg shadow-md bottom-[1rem]`}
              >
                <>
                  <div>
                    <div
                      className="text-left cursor-pointer flex border-b py-1 px-2"
                      onClick={() => openAddressModal(row)}
                    >
                      <div
                        style={{
                          maskImage: `url(${edit})`,
                          WebkitMaskImage: `url(${edit})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="self-center singleViews mr-2 h-4 w-4 "
                      />
                      {/* <img src={edit} className="w-4 h-4 mr-2" />{" "} */}
                      <span className="self-center">Edit </span>
                    </div>
                    <div className="text-left cursor-pointer flex py-1 px-2" onClick={() => deleteAddress(row._id, customerId)}
                    >
                      <div
                        style={{
                          maskImage: `url(${delete1})`,
                          WebkitMaskImage: `url(${delete1})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="self-center singleViews mr-2 h-4 w-4 "
                      />
                      {/* <img src={delete1} className="w-4 h-4 mr-2" /> */}
                      <span className="self-center">Delete</span>
                    </div>

                  </div>
                </>
              </SingleView>
            )
            }
          </div >
        );
      },
    },
  ];

  const deleteAddress = async (id, customerId) => {
    setLoading(true);
    // localStorage.setItem("customer", "Settings");
    const result = await deleteCustomerAddress(id, customerId)
    if (result.code === 200) {
      SetPrimaryText("Address Deleted Successfully");
      SetSecondaryText("Address deleted successfully");
      SetIsModalOpen(true);
      setTimer(3);
      getCustomerDetails();
      // localStorage.setItem("customer", "Settings");

    } else {
      console.error("Error deleting Customer address:", result.message);
      SetPrimaryText("Error deleting customer address");
      SetSecondaryText(
        "There was an error deleting the Customer address. Please try again."
      );
      SetIsModalOpen(true);
    }
    setLoading(false);
  }

  const handleDownload = () => {
    console.log("Download");
    textFile(addressData);
  };

  const address = useFormik({
    initialValues: initialFormValues1,
    enableReinitialize: true,
    validationSchema: Yup.object({
      address: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      state: Yup.string()
        .required("Required"),
      city: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      zip: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters")
        .max(6, "Must be exactly 6 characters"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      localStorage.setItem("customer", "Settings");
      console.log(values)
      const data = {
        customerId: customerId,
        addressId: values.addressId,
        city: values.city,
        street: values.address,
        state: values.state,
        zip: values.zip
      };
      try {
        setEditAddressLoader(true);
        const result = await editCustomerAddressById(
          data);
        console.log(result);
        SetPrimaryText("Address Updated Successfully");
        SetSecondaryText("Address updated successfully");
        SetIsModalOpen(true);
        setIsAddressModalOpen(false);
        getCustomerDetails();
        formik.resetForm();
        // customerDetails(props.id)
        // localStorage.setItem("customer", "Settings");
        setEditAddressLoader(false);
        setTimer(3);
      } catch (error) {
        console.error("Error updating Customer address:", error);
        SetPrimaryText("Error Updating Customer address");
        SetSecondaryText(
          "There was an error updating the Customer address. Please try again."
        );
        SetIsModalOpen(true);
        setEditAddressLoader(false);
      } finally {
        setEditAddressLoader(false);
      }
    },
  });

  const Addaddress = useFormik({
    initialValues: initialFormValues1,
    enableReinitialize: true,
    validationSchema: Yup.object({
      address: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      state: Yup.string()
        .required("Required"),
      city: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      zip: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters")
        .max(6, "Must be exactly 6 characters"),
    }),

    onSubmit: async (values) => {
      localStorage.setItem("customer", "Settings");

      setLoading(true);
      try {
        const address = {
          address: values,
        }
        const result = await addCustomerAddressById(customerId, address);
        console.log(result);
        if (result.code == 200) {
          SetIsModalOpen(true);
          SetPrimaryText(" New Address Added Successfully");
          SetSecondaryText("New Address Added Successfully");
          // setMessage("Address Added Successfully");
          setLoading(false);
          closeAddAddress();
          getCustomerDetails();

          // setActiveTab("Settings");
        } else {
          setLoading(false);
          formik.setFieldError("address", "Address Already Added");
        }
      } catch (error) {
        console.error("Error adding address:", error);
      } finally {
        localStorage.setItem("customer", "Settings");
        setLoading(false);
      }
    },
  });


  const checkAllStatusTrue = (notificationSettings, index) => {
    console.log(notificationSettings)
    const section = notificationSettings.find((n) => n.index === index);
    if (!section) return false;
    return section.sections.every((s) => s.status === true);
  };



  const handleToggleAll = (allStatusTrue, setNotificationSettings, i) => {
    setNotificationSettings((prevSettings) => {
      const newSettings = prevSettings.map((notification, index) => {
        console.log(notificationSettings, i,);

        // Check if the current index matches the target index
        if (notification.index == i && notification.sections) {
          return {
            ...notification,
            sections: notification.sections.map((section) => ({
              ...section,
              status: !allStatusTrue, // Toggle status based on allStatusTrue
            })),
          };
        }
        return notification; // Leave other notifications unchanged
      });
      const updatedNotifications = transformDataForAPI(newSettings);
      updateNotification(updatedNotifications);

      return newSettings;
    });
  };


  const handleAddOrUpdate1 = (value, allStatusTrue, setNotificationSettings, i) => {
    setNotificationSettings((prevSettings) =>
      prevSettings.map((group, index) => {
        console.log(group.index == i)
        if (group.index == i) {
          return {
            ...group,
            sections: group.sections.map((section) =>
              section.action === value
                ? { ...section, status: !section.status }
                : section
            ),
          };
        }
        return group;
      })
    );

    // Pass the updated settings to the API
    setNotificationSettings((prevSettings) => {
      const updatedNotifications = transformDataForAPI(prevSettings);
      updateNotification(updatedNotifications);
      return prevSettings;
    });
  };

  // Transform function to structure data as per API requirements
  const transformDataForAPI = (notificationSettings) => {
    const transformedData = notificationSettings.reduce((acc, group) => {
      const apiFieldName = group.apiFieldName;
      const sections = group.sections.reduce((innerAcc, section) => {
        innerAcc[section.action] = section.status;
        return innerAcc;
      }, {});

      acc[apiFieldName] = sections;
      return acc;
    }, {});

    return {
      ...transformedData,
    };
  };

  // Update notification function
  const updateNotification = (updatedNotifications) => {
    console.log(notification)
    updateNotificationData(notification._id, updatedNotifications).then((res) => {
      console.log(res);
    });
  };

  const closeNotification = () => {
    setIsNotificationOpen(false);
  }

  const openNotification = async (id) => {
    const data = await getUserNotificationData(id, 'Customer');
    console.log(data, 'there------------------------');
    const notifications = data.result.notifications;
    const filteredNotifications = Notifications.filter(notification => {
      const apiFieldName = notification.apiFieldName;
      return data.result.notifications[apiFieldName] != undefined;
    });
    setNotificationList(filteredNotifications)
    console.log(filteredNotifications);

    // Map settings for toggles
    const mappedSettings = {};
    Object.entries(notifications).forEach(([categoryKey, categoryValue]) => {
      if (typeof categoryValue === "object" && categoryValue != null) {
        console.log(categoryValue)
        Object.entries(categoryValue).forEach(([key, value]) => {
          if (typeof value === "boolean") {
            mappedSettings[key] = value;
          }
        });
      }
    });

    // Update notificationList to reflect the new settings

    const unifiedNotifications = Notifications.map(notification => {
      const apiSection = notifications[notification.apiFieldName];


      return {
        index: notification.index,
        title: notification.title,
        apiFieldName: notification.apiFieldName,
        sections: notification.sections.map(section => {
          if (apiSection && apiSection[section.action] != undefined) {
            return {
              label: section.label,
              action: section.action,
              status: apiSection[section.action]
            };
          }
          return null;
        }).filter(item => item !== null)
      };
    });

    setNotificationSettings(unifiedNotifications);
    setNotification(data.result);
    setIsNotificationOpen(true);
  };
  return (
    <>
      {loading1 ? (
        <div className=" h-[500px] w-full flex py-5">
          <div className="self-center mx-auto">
            <RotateLoader color="#333" />
          </div>
        </div>
      ) : (
        <div className="my-8">
          <Headbar />
          <div className="flex mt-2">
            <div className="pl-3">
              <p className="font-bold text-[36px] leading-9	mb-[3px]">
                Manage Users
              </p>
              <ul className="flex self-center">
                <li className="text-sm font-semibold ml-1 pt-[1px]">
                  {" "}
                  Edit Account{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className="px-4 relative">
            <Grid className="">

              <div
                className={` rounded-[30px] px-2 py-3 border-[1px] border-Light-Grey col-span-6 flex`}>
                {activeButton != "myAccount" ? <InActiveButton
                  className={`flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey `}
                  onClick={() => handleButtonClick("myAccount")}
                >   My Account</InActiveButton> : <Button
                  className={`flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey `}
                  onClick={() => handleButtonClick("myAccount")}
                >   My Account</Button>
                }
                {activeButton != "Address" ?
                  <InActiveButton
                    className={`flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey `}
                    onClick={() => handleButtonClick("Address")}
                  >   Address</InActiveButton> :
                  <Button
                    className={`flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey `}
                    onClick={() => handleButtonClick("Address")}
                  >   Address</Button>
                }
              </div>
              <div className="col-span-4">

              </div>
              {activeButton == "Address" && <div className="col-span-2">
                <InActiveButton className=" self-center mb-3 rounded-xl ml-auto flex border-[1px] border-Light-Grey" onClick={() => openAddAddressModal()}>

                  <div
                    style={{
                      maskImage: `url(${AddItem})`,
                      WebkitMaskImage: `url(${AddItem})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center pr-1 py-1 h-4 w-4"
                  />
                  {/* <img src={AddItem} className="self-center" alt="AddItem" />{" "} */}
                  <span className=" ml-2 text-[14px] font-Regular ">
                    Add Address
                  </span>
                </InActiveButton>
              </div>}
            </Grid>
            {activeButton === "myAccount" ? (
              <>
                <SingleView className="px-8 mt-8 py-4 rounded-[30px]">
                  <Grid>
                    <div className="col-span-2 text-left">
                      <p className="text-base font-semibold my-3">
                        {" "}
                        Dealer Details
                      </p>
                    </div>
                    <div className="col-span-10 self-center">
                      <hr />
                    </div>
                  </Grid>
                  <Grid className="mx-auto ">
                    <div className="col-span-2 self-center border-r border-[#4e4e4e]"></div>
                    <div className="col-span-3 border-r border-[#4e4e4e]">
                      <div className="flex">
                        <div className="self-center bg-[#FFFFFF08] backdrop-blur rounded-xl mr-4">
                          <img src={dealer} alt="dealer" />
                        </div>
                        <div className="self-center w-[80%]">
                          <p className="text-base font-medium leading-5	">
                            Account Name
                          </p>
                          <p className="opacity-50 text-sm	font-medium">
                            {details?.dealerName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-5">
                      <div className="flex justify-center">
                        <div className="self-center bg-[#FFFFFF08] rounded-xl mr-4">
                          <img src={terms} className="" alt="terms" />
                        </div>
                        <div className="self-center ">
                          <p className="text-base font-medium leading-5">
                            Address
                          </p>
                          <p className="opacity-50	text-sm font-medium">
                            {details?.dealer?.street} {", "}
                            {details?.dealer?.city}
                            {", "}
                            {details?.dealer?.state} {details?.dealer?.zip}
                            {", "}
                            {details?.dealer?.country}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2"></div>
                  </Grid>
                  {details?.resellerId1 !== null && (
                    <>
                      <Grid>
                        <div className="col-span-2 text-left">
                          <p className="text-base font-semibold my-3">
                            {" "}
                            Reseller Details
                          </p>
                        </div>
                        <div className="col-span-10 self-center">
                          <hr />
                        </div>
                      </Grid>
                      <Grid className="mx-auto ">
                        <div className="col-span-2 self-center border-r border-[#4e4e4e]"></div>
                        <div className="col-span-3 border-r border-[#4e4e4e]">
                          <div className="flex">
                            <div className="self-center bg-[#FFFFFF08] backdrop-blur rounded-xl mr-4">
                              <img src={dealer} alt="dealer" />
                            </div>
                            <div className="self-center w-[80%]">
                              <p className="text-base font-medium leading-5	">
                                Account Name
                              </p>
                              <p className="opacity-50 text-sm	font-medium">
                                {details?.reseller?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-5">
                          <div className="flex justify-center">
                            <div className="self-center bg-[#FFFFFF08] rounded-xl mr-4">
                              <img src={terms} className="" alt="terms" />
                            </div>
                            <div className="self-center ">
                              <p className="text-base font-medium leading-5">
                                Address
                              </p>
                              <p className="opacity-50	text-sm font-medium">
                                {details?.reseller?.street} {", "}
                                {details?.reseller?.city}
                                {", "}
                                {details?.reseller?.state} {details?.reseller?.zip}
                                {", "}
                                {details?.reseller?.country}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2"></div>
                      </Grid>
                    </>
                  )}

                  <Grid className="mt-5">
                    <div className="col-span-2 text-left">
                      <p className="text-base font-semibold">
                        {" "}
                        My Details
                      </p>
                    </div>
                    <div className="col-span-10 self-center">
                      <hr />
                    </div>
                  </Grid>
                  <Grid className="mx-auto mt-4">
                    <div className="col-span-2 self-center border-r border-[#4e4e4e]"></div>
                    <div className="col-span-3 border-r border-[#4e4e4e]">
                      <div className="flex">
                        <div className="self-center bg-[#FFFFFF08] backdrop-blur rounded-xl mr-4">
                          <img src={dealer} alt="dealer" />
                        </div>
                        <div className="self-center w-[80%]">
                          <p className="text-base font-medium leading-5	">
                            Account Name
                          </p>
                          <p className="opacity-50 text-sm	font-medium">
                            {details?.username}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-5">
                      <div className="flex justify-center">
                        <div className="self-center bg-[#FFFFFF08] rounded-xl mr-4">
                          <img src={terms} className="" alt="terms" />
                        </div>
                        <div className="self-center">
                          <p className="text-base font-medium leading-5">
                            Address
                          </p>
                          <p className="opacity-50	text-sm font-medium">
                            {details?.street} {", "}
                            {details?.city}
                            {", "}
                            {details?.state} {details?.zip}
                            {", "}
                            {details?.country}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2"></div>
                  </Grid>
                </SingleView>
                <Card className="px-8 pb-8 pt-4 mt-5 mb-8 drop-shadow-4xl border-[1px] border-Light-Grey  rounded-xl relative">
                  <p className="text-xl font-semibold mb-5">Change Password</p>
                  <form onSubmit={passwordChnageForm.handleSubmit}>
                    <Grid>
                      <div className="col-span-4">
                        <PasswordInput
                          type="password"
                          name="oldPassword"
                          label="Old Password"
                          value={passwordChnageForm.values.oldPassword}
                          onChange={passwordChnageForm.handleChange}
                          onBlur={passwordChnageForm.handleBlur}
                          isPassword
                          className="!bg-white"
                        />
                        {passwordChnageForm.touched.oldPassword &&
                          passwordChnageForm.errors.oldPassword && (
                            <div className="text-red-500">
                              {passwordChnageForm.errors.oldPassword}
                            </div>
                          )}
                      </div>

                      <div className="col-span-4">
                        <PasswordInput
                          type="password"
                          name="newPassword"
                          label="New Password"
                          isPassword
                          className="!bg-white"
                          value={passwordChnageForm.values.newPassword}
                          onChange={passwordChnageForm.handleChange}
                          onBlur={passwordChnageForm.handleBlur}
                        />
                        {passwordChnageForm.touched.newPassword &&
                          passwordChnageForm.errors.newPassword && (
                            <div className="text-red-500">
                              {passwordChnageForm.errors.newPassword}
                            </div>
                          )}
                      </div>
                      <div className="col-span-4">
                        <PasswordInput
                          type="password"
                          name="confirmPassword"
                          label="Confirm Password"
                          isPassword
                          className="!bg-white"
                          value={passwordChnageForm.values.confirmPassword}
                          onChange={passwordChnageForm.handleChange}
                          onBlur={passwordChnageForm.handleBlur}
                        />
                        {passwordChnageForm.touched.confirmPassword &&
                          passwordChnageForm.errors.confirmPassword && (
                            <div className="text-red-500">
                              {passwordChnageForm.errors.confirmPassword}
                            </div>
                          )}
                      </div>
                    </Grid>
                    <div className="mt-4 text-right">
                      <Button type="submit">Change Password</Button>
                    </div>
                  </form>
                </Card>

                {loading ? (
                  <div className=" h-[400px] w-full flex py-5 ">
                    <div className="self-center mx-auto">
                      <RotateLoader color="#333" />
                    </div>
                  </div>
                ) : (
                  <Card className="px-8 pb-8 pt-4 mt-8 mb-8 drop-shadow-4xl border-[1px] border-Light-Grey  rounded-xl relative">
                    {isprimary && (
                      <div className="bg-gradient-to-r from-[#dfdfdf] to-[#e9e9e9] rounded-[20px] absolute top-[-17px] right-[-12px] p-3">
                        <Button onClick={() => openUserModal()}>
                          {" "}
                          + Add Member
                        </Button>
                      </div>
                    )}
                    <p className="text-xl font-semibold mb-3">Users List</p>

                    <DataTable
                      className="data-table-container" columns={isprimary ? columns : columns12}
                      data={userList}
                      highlightOnHover
                      sortIcon={
                        <>
                          {" "}
                          <div
                            style={{
                              maskImage: `url(${shorting})`,
                              WebkitMaskImage: `url(${shorting})`,
                              maskRepeat: "no-repeat",
                              WebkitMaskRepeat: "no-repeat",
                              maskPosition: "center",
                              WebkitMaskPosition: "center",
                              maskSize: "contain",
                              WebkitMaskSize: "contain",
                            }}
                            className="ml-2 tabless"
                          />
                        </>
                      }

                      noDataComponent={<CustomNoDataComponent />}
                    />
                  </Card>
                )}
              </>
            ) :
              <>
                <Card className=" mt-4 border-[1px] border-Light-Grey rounded-xl p-5 ">
                  <div className="users">
                    <Grid>
                      <div className="col-span-7 self-center">
                        <p className="text-lg font-semibold">Customer Address Details </p>
                      </div>
                      {addressData.length > 0 && <div className="col-span-5 self-center text-end">
                        <Button onClick={() => handleDownload()}>Export Addresses</Button>
                      </div>}

                    </Grid>
                    <DataTable
                      className="data-table-container" columns={Address}
                      data={addressData}
                      sortIcon={
                        <div
                          style={{
                            maskImage: `url(${shorting})`,
                            WebkitMaskImage: `url(${shorting})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="ml-2 tabless"
                        />
                      }
                      highlightOnHover
                      draggableColumns={false}
                      pagination
                      paginationPerPage={rowsPerPage}
                      paginationComponentOptions={{
                        rowsPerPageText: "Rows per page:",
                        rangeSeparatorText: "of",


                      }}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      noDataComponent={<CustomNoDataComponent />}
                    />
                  </div>
                </Card>
              </>}
          </div>
        </div>
      )}

      {/* Modal Primary Popop */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="text-center py-3">
          <img src={Primary} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            {primaryText}
          </p>
          <p className="text-base font-medium mt-4">
            {secondaryText} <br />
            Redirecting Back to User List in {timer} Seconds
          </p>
        </div>
      </Modal>

      {/* Modal Delete Popop */}
      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <div className="text-center py-3">
          <img src={assign} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-semibold">
            Would you like to delete it?
          </p>
          <Grid className="!grid-cols-4 my-5 ">
            <div className="col-span-1"></div>
            <Button
              onClick={() => {
                deleteUser();
              }}
            >
              Yes
            </Button>
            <InActiveButton
              className="border w-full !border-Bright-Grey !text-sm !font-Regular"
              onClick={() => closeModal1()}
            >
              No
            </InActiveButton>
            <div className="col-span-1"></div>
          </Grid>
        </div>
      </Modal>

      {/* Modal Delete Msg Popop */}
      <Modal isOpen={isModalOpen12} onClose={closeModal12}>
        <div className="text-center py-3">
          <img src={deleteUser10} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-semibold">
            Deleted Successfully
          </p>
          <p className=" text-base font-medium mt-2">
            You have successfully deleted this user.
          </p>
          <p className="text-base font-medium mt-2">
            Redirecting Back to User List in {timer} seconds
          </p>
        </div>
      </Modal>

      <Modal isOpen={isModalOpen2} onClose={closeModal2}>
        {editLoading ? (
          <div className="h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
        ) : (
          <div className=" py-3">
            <p className="text-3xl text-center mb-5 mt-2 font-semibold">
              Edit User
            </p>
            <form className="mt-8" onSubmit={formik1.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={formik1.values.firstName}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    error={
                      formik1.touched.firstName && formik1.errors.firstName
                    }
                  />
                  {formik1.touched.firstName && formik1.errors.firstName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik1.errors.firstName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={formik1.values.lastName}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    error={formik1.touched.lastName && formik1.errors.lastName}
                  />
                  {formik1.touched.lastName && formik1.errors.lastName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik1.errors.lastName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="position"
                    label="Position"
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={formik1.values.position}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    error={formik1.touched.position && formik1.errors.position}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Phone #"
                    nonumber={true}
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    value={formik1.values.phoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      console.log(sanitizedValue);
                      formik1.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: sanitizedValue,
                        },
                      });
                    }}
                    onBlur={formik1.handleBlur}
                    onWheelCapture={(e) => {
                      e.preventDefault();
                    }}
                    minLength={"10"}
                    maxLength={"10"}
                    error={
                      formik1.touched.phoneNumber && formik1.errors.phoneNumber
                    }
                  />
                  {(formik1.touched.phoneNumber || formik1.submitCount > 0) &&
                    formik1.errors.phoneNumber && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {formik1.errors.phoneNumber}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Select
                    label="Status"
                    required={true}
                    name="status"
                    placeholder=""
                    onChange={handleSelectChange}
                    disabled={active}
                    className="!bg-white"
                    options={status}
                    value={formik1.values.status}
                    onBlur={formik1.handleBlur}
                    error={formik1.touched.status && formik1.errors.status}
                  />
                  {formik1.touched.status && formik1.errors.status && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik1.errors.status}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid className="!grid-cols-5 my-5  px-8">
                <div className="col-span-2">
                  <InActiveButton
                    className="w-full"
                    onClick={() => closeModal2()}
                  >
                    Cancel
                  </InActiveButton>
                </div>

                <div className="col-span-3">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        )}
      </Modal>

      {/* Modal Edit Popop */}
      <Modal isOpen={isUserModalOpen} onClose={closeUserModal}>
        {addLoading ? (
          <div className="h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
        ) : (
          <div className=" py-3">
            <p className="text-3xl text-center mb-5 mt-2 font-semibold">
              Add New User
            </p>
            <form className="mt-8" onSubmit={userValues.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={userValues.values.firstName}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.firstName &&
                      userValues.errors.firstName
                    }
                  />
                  {userValues.touched.firstName &&
                    userValues.errors.firstName && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.firstName}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={userValues.values.lastName}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.lastName && userValues.errors.lastName
                    }
                  />
                  {userValues.touched.lastName &&
                    userValues.errors.lastName && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.lastName}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    className="!bg-white"
                    required={true}
                    placeholder=""
                    maxLength={"30"}
                    value={userValues.values.email}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={userValues.touched.email && userValues.errors.email}
                  />
                  {/* {userValues.touched.position && userValues.errors.position && (
                <div className="text-red-500 text-sm pl-2 pt-2">
                  {userValues.errors.position}
                </div>
              )} */}
                </div>
                <div className="col-span-6">
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Phone Number"
                    required={true}
                    nonumber={true}
                    className="!bg-white"
                    placeholder=""
                    value={userValues.values.phoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      console.log(sanitizedValue);
                      userValues.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: sanitizedValue,
                        },
                      });
                    }}
                    onBlur={userValues.handleBlur}
                    onWheelCapture={(e) => {
                      e.preventDefault();
                    }}
                    minLength={"10"}
                    maxLength={"10"}
                    error={
                      userValues.touched.phoneNumber &&
                      userValues.errors.phoneNumber
                    }
                  />
                  {(userValues.touched.phoneNumber ||
                    userValues.submitCount > 0) &&
                    userValues.errors.phoneNumber && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.phoneNumber}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="position"
                    label="Position"
                    className="!bg-white"
                    // required={true}
                    placeholder=""
                    maxLength={"30"}
                    value={userValues.values.position}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.position && userValues.errors.position
                    }
                  />
                  {/* {userValues.touched.position && userValues.errors.position && (
                <div className="text-red-500 text-sm pl-2 pt-2">
                  {userValues.errors.position}
                </div>
              )} */}
                </div>
                <div className="col-span-6">
                  <p className="flex text-[12px] font-semibold mt-3 mb-6">
                    Do you want to create an account?
                    <RadioButton
                      id="yes-create-account"
                      label="Yes"
                      value="yes"
                      disabled={!userStatus}
                      checked={createAccountOption === "yes"}
                      onChange={handleRadioChange}
                    />
                    <RadioButton
                      id="no-create-account"
                      label="No"
                      value="no"
                      disabled={!userStatus}
                      checked={createAccountOption === "no"}
                      onChange={handleRadioChange}
                    />
                  </p>
                </div>
              </Grid>
              <Grid className="!grid-cols-5 my-5  px-8">
                <div className="col-span-2">
                  <InActiveButton
                    className="w-full !text-sm !font-Regular"
                    onClick={() => closeUserModal()}
                  >
                    Cancel
                  </InActiveButton>
                </div>

                <div className="col-span-3">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        )}
      </Modal>

      <Modal isOpen={isPasswordOpen} onClose={closePassword}>
        <Button
          onClick={closePassword}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <div className="text-center py-3">
          <img src={deleteUser123} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            {firstMessage}
          </p>
          <p className="text-base font-medium mt-4">
            {secondMessage}
          </p>
        </div>
      </Modal>

      <Modal isOpen={isAddressModalOpen} onClose={closeAddressModal}>
        {editAddressLoader ?
          <div className="h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div> :
          <div className=" py-3">
            <p className=" text-center text-3xl mb-5 mt-2 font-bold text-light-black">
              Edit Address
            </p>
            <form onSubmit={address.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-12">
                  <Input
                    type="text"
                    name="address"
                    label="Street Address"
                    className="!bg-white"
                    value={address.values.address}
                    onChange={address.handleChange}
                    onBlur={address.handleBlur}
                    disabled={loading}
                  />
                  {address.touched.address &&
                    address.errors.address && (
                      <p className="text-red-500 text-xs pl-2">
                        {address.errors.address}
                      </p>
                    )}
                </div>
                <div className="col-span-4">
                  <Input
                    type="text"
                    name="city"
                    label="City"
                    className="!bg-white"
                    placeholder=" "
                    maxLength={"20"}
                    value={address.values.city}
                    onChange={address.handleChange}
                    onBlur={address.handleBlur}
                    error={address.touched.city && address.errors.city}
                  />
                  {address.touched.city && address.errors.city && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {address.errors.city}
                    </div>
                  )}
                </div>
                <div className="col-span-4">
                  <Select
                    label="State"
                    name="state"
                    placeholder=""
                    className="!bg-white"
                    onChange={handleSelectChange12}
                    options={state}
                    value={address.values.state}
                    onBlur={address.handleBlur}
                    error={address.touched.state && address.errors.state}
                  />
                  {address.touched.state && address.errors.state && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {address.errors.state}
                    </div>
                  )}
                </div>
                <div className="col-span-4">
                  <Input
                    type="number"
                    name="zip"
                    label="Zipcode"
                    className="!bg-white"
                    placeholder=""
                    zipcode={true}
                    value={address.values.zip}
                    onChange={address.handleChange}
                    onBlur={address.handleBlur}
                    minLength={"5"}
                    maxLength={"6"}
                    error={address.touched.zip && address.errors.zip}
                  />
                  {address.touched.zip && address.errors.zip && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {address.errors.zip}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid className="drop-shadow-5xl px-8 mt-8">
                <div className="col-span-4">
                  <InActiveButton
                    type="button"
                    className="border w-full  !text-sm !font-Regular"
                    onClick={closeAddressModal}
                  >
                    Cancel
                  </InActiveButton>
                </div>
                <div className="col-span-8">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>}

      </Modal>
      <Modal isOpen={addAddressModalOpen} onClose={closeAddAddress}>
        <div className=" py-3">
          <p className=" text-center text-3xl mb-5 mt-2 font-bold text-light-black">
            Add Address
          </p>
          <form onSubmit={Addaddress.handleSubmit}>
            <Grid className="px-8">
              <div className="col-span-12">
                <Input
                  type="text"
                  name="address"
                  label="Street Address"
                  className="!bg-white"
                  value={Addaddress.values.address}
                  onChange={Addaddress.handleChange}
                  onBlur={Addaddress.handleBlur}
                  required={true}
                  disabled={loading}
                />
                {Addaddress.touched.address &&
                  Addaddress.errors.address && (
                    <p className="text-red-500 text-xs pl-2">
                      {Addaddress.errors.address}
                    </p>
                  )}
              </div>
              <div className="col-span-4">
                <Input
                  type="text"
                  name="city"
                  label="City"
                  className="!bg-white"
                  placeholder=" "
                  maxLength={"20"}
                  required={true}
                  value={Addaddress.values.city}
                  onChange={Addaddress.handleChange}
                  onBlur={Addaddress.handleBlur}
                  error={Addaddress.touched.city && Addaddress.errors.city}
                />
                {Addaddress.touched.city && Addaddress.errors.city && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {Addaddress.errors.city}
                  </div>
                )}
              </div>
              <div className="col-span-4">
                <Select
                  label="State"
                  name="state"
                  placeholder=""
                  className="!bg-white"
                  required={true}
                  onChange={handleSelectChange1}
                  options={state}
                  value={Addaddress.values.state}
                  onBlur={Addaddress.handleBlur}
                  error={Addaddress.touched.state && Addaddress.errors.state}
                />
                {Addaddress.touched.state && Addaddress.errors.state && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {Addaddress.errors.state}
                  </div>
                )}
              </div>
              <div className="col-span-4">
                <Input
                  type="number"
                  name="zip"
                  label="Zipcode"
                  className="!bg-white"
                  placeholder=""
                  required={true}
                  zipcode={true}
                  value={Addaddress.values.zip}
                  onChange={Addaddress.handleChange}
                  onBlur={Addaddress.handleBlur}
                  minLength={"5"}
                  maxLength={"6"}
                  error={Addaddress.touched.zip && Addaddress.errors.zip}
                />
                {Addaddress.touched.zip && Addaddress.errors.zip && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {Addaddress.errors.zip}
                  </div>
                )}
              </div>
            </Grid>
            <Grid className="drop-shadow-5xl px-8 mt-8">
              <div className="col-span-4">
                <InActiveButton
                  type="button"
                  className="border w-full !text-sm !font-Regular"
                  onClick={closeAddAddress}
                >
                  Cancel
                </InActiveButton>
              </div>
              <div className="col-span-8">
                <Button type="submit" className="w-full">
                  Submit
                </Button>
              </div>
            </Grid>
          </form>
        </div>
      </Modal>

      <Modal isOpen={isNotificationOpen} onClose={closeNotification} className="!w-[90%]">
        <Button
          onClick={closeNotification}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img src={Cross} className="w-full h-full text-black rounded-full p-0" />
        </Button>
        <div className="py-3">
          <p className="text-3xl font-bold text-center mb-5">Notification Settings</p>
          <div className="overflow-y-scroll min-h-[200px] max-h-[400px]">
            <Grid className="!grid-cols-2 !gap-1">
              {Object.entries(notificationList || []).map(([key, value], i) => {

                if (!value) {
                  return null;
                }

                const { index, title, sections, apiFieldName } = value;
                const allStatusTrue = checkAllStatusTrue(notificationSettings, activeIndex1);

                return (
                  <div key={index} className="mb-1">
                    <CollapsibleDiv
                      key={index}
                      ShowData={showdata}
                      activeIndex={activeIndex1}
                      setActiveIndex={setActiveIndex1}
                      imageClass="w-10 h-10"
                      className="!my-2"
                      index={index}
                      title={
                        <SingleView className="border-Gray28 border px-4 py-2 rounded-t-[22px]">
                          <p className="text-lg font-bold">{title}</p>
                        </SingleView>
                      }
                    >

                      <div className="px-4 pt-2 pb-4 border">
                        <div className="text-end ml-auto mb-2">
                          <Button
                            type="button"
                            className="!text-sm"
                            onClick={() =>
                              handleToggleAll(allStatusTrue, setNotificationSettings, value.index)
                            }
                          >
                            {allStatusTrue ? "Unselect All" : "Select All"}
                          </Button>
                        </div>
                        <Grid className="!grid-cols-12 !gap-2">
                          {notificationSettings
                            .find((n) => n.index === activeIndex1)
                            ?.sections?.map(({ label, action }, itemIdx) => {
                              if (!label || !action) return null;

                              const status = notificationSettings
                                .find((n) => n.index === activeIndex1)
                                ?.sections.find((s) => s.action === action)?.status ?? false;

                              return (
                                <div className="col-span-6" key={itemIdx}>
                                  <Grid className="!gap-0">
                                    <div className="col-span-8 self-center">
                                      <p className="flex text-[12px] font-semibold justify-between">{label}</p>
                                    </div>
                                    <div className="col-span-4">
                                      <SwitchButton
                                        isOn={status}
                                        handleToggle={() =>
                                          handleAddOrUpdate1(action, allStatusTrue, setNotificationSettings, value.index)
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </div>
                              );
                            })}
                        </Grid>

                      </div>
                    </CollapsibleDiv>
                  </div>
                );
              })}

            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CustomerUser;
